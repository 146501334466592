import React, { useEffect, useState } from "react";
import {
  addReviews,
  deleteReview,
  getAllReview,
  reviewById,
  updateReview,
} from "../../fetch/testimonialFetch";
import Swal from "sweetalert2";
import Select from "react-select";
const Reviews = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [msg, setMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState(0);
  const [edit, setEdit] = useState(null);
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const numberOfAvatars = 10; // Misalnya, Anda memiliki 10 avatar
  const baseUrl = "https://api.ramusen.io/images/avatar/";

  const avatars = Array.from({ length: numberOfAvatars }, (_, index) => {
    const avatarNumber = index + 1; // Karena index dimulai dari 0
    return {
      fileName: `avatar-${avatarNumber}.png`,
      name: `Avatar ${avatarNumber}`,
      url: `${baseUrl}avatar-${avatarNumber}.png`,
    };
  });

  const [User, setUser] = useState([
    {
      name: "",
      profession: "",
      photo: "",
      rating: "",
      testimonial: "",
    },
  ]);
  const [editUser, setEditUser] = useState([
    {
      name: "",
      profession: "",
      photo: "",
      rating: "",
      testimonial: "",
    },
  ]);
  const handleSearch = (e) => {
    e.preventDefault();
    setMsg("");
  };
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    getAllReview((result) => {
      setData(result);
    });
  };
  const handleChange = (selectedOption) => {
    if (edit) {
      setEditUser({
        ...editUser,
        photo: selectedOption.value,
      });
    } else {
      setUser({ ...User, photo: selectedOption.value });
    }
  };
  const handleStarChange = (selectedOption) => {
    if (edit) {
      setEditUser({
        ...editUser,
        rating: selectedOption.value,
      });
    } else {
      setUser({ ...User, rating: selectedOption.value });
    }
  };
  const StarOption = ({ innerProps, data }) => {
    return (
      <div {...innerProps} style={{ display: "flex", alignItems: "center" }}>
        {"★".repeat(data.value)}
        <span style={{ marginLeft: "10px" }}>{data.label}</span>
      </div>
    );
  };

  const CustomOption = (props) => (
    <div
      {...props.innerProps}
      style={{ display: "flex", alignItems: "center" }}
    >
      <img
        src={props.data.image}
        alt={props.data.label}
        style={{ width: "50px", height: "50px", marginRight: "10px" }}
      />
      {props.data.label}
    </div>
  );
  const starOptions = [
    { value: 1, label: "1 Bintang" },
    { value: 2, label: "2 Bintang" },
    { value: 3, label: "3 Bintang" },
    { value: 4, label: "4 Bintang" },
    { value: 5, label: "5 Bintang" },
  ];

  const options = avatars.map((avatar) => ({
    value: avatar.fileName, // file name as the value
    label: avatar.name, // display name for the label
    image: avatar.url, // image URL for the avatar
  }));
  const newKarir = () => {
    const reviewData = {
      name: User.name,
      profession: User.profession,
      photo: User.photo,
      rating: User.rating,
      testimonial: User.testimonial,
    };
    console.log("Data user :", User);
    User.name === "" ||
    User.profession === "" ||
    User.photo === "" ||
    User.rating === "" ||
    User.testimonial === ""
      ? Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please Input Data First !!",
        })
      : addReviews(reviewData).then(() => {
          fetchData(); // Memperbarui data
          setShowModal(false); // Menutup modal
          setUser({
            name: "",
            profession: "",
            photo: "",
            rating: "",
            testimonial: "",
          });
        });
  };
  const editData = () => {
    if (editUser && id !== 0) {
      const updatedUserData = {
        id: id,
        name: editUser.name,
        profession: editUser.profession,
        photo: editUser.photo,
        rating: editUser.rating,
        testimonial: editUser.testimonial,
      };

      console.log(updatedUserData);
      updateReview(id, updatedUserData)
        .then(() => {
          fetchData();
          setShowModal(false);
          setEdit(null);
          setId(0);
        })
        .catch((error) => {
          console.error("Error updating user:", error);
        });
    }
  };
  const handleDelete = (id) => {
    deleteReview(id, fetchData);
  };

  const handleEdit = (user) => {
    setEdit(user); // Memasukkan data user ke state
    setShowModal(true); // Menampilkan modal
    setId(user);
    reviewById(user, (cb) => {
      setEditUser({
        name: cb.name,
        profession: cb.profession,
        photo: cb.photo,
        rating: cb.rating,
        testimonial: cb.testimonial,
      });
    });
  };
  return (
    <>
      <div className="container dashboard-content">
        <div className="dashboard-content-container">
          <div className="dashboard-content-header">
            <div className="dashboard-content-tittle">
              <h2>Review List</h2>
            </div>
            <form onSubmit={handleSearch}>
              <div className="dashboard-content-search">
                <input
                  type="text"
                  className="dashboard-content-input"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Find something here..."
                />
                <button type="submit" className="btn btn-info">
                  Search
                </button>
              </div>
            </form>
          </div>

          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <th>No</th>
                <th>Nama</th>
                <th>Profesi</th>
                <th>Avatar</th>
                <th>Rating</th>
                <th>Testimonial</th>
                <th>Action</th>
              </thead>

              {data ? (
                <tbody>
                  {data.map((review, i) => (
                    <tr key={i}>
                      <td>
                        <span>{i + 1}</span>
                      </td>
                      <td>
                        <span>{review.name}</span>
                      </td>
                      <td>
                        <span>{review.profession}</span>
                      </td>
                      <td>
                        <img
                          src={baseUrl + review.photo}
                          alt={review.name}
                          style={{ width: "50px", height: "50px" }}
                        />
                      </td>
                      <td>
                        {"★".repeat(review.rating)}
                        {"☆".repeat(5 - review.rating)}
                      </td>
                      <td>
                        <span>{review.testimonial}</span>
                      </td>
                      <td>
                        <div
                          class="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            class="btn btn-secondary"
                            onClick={() => handleEdit(review.id)}
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            class="btn btn-danger"
                            onClick={() => handleDelete(review.id)}
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <div className="text-center">Data Tidak Ditemukan</div>
              )}
            </table>
          </div>
        </div>
      </div>
      <div className="fab">
        <button
          type="button"
          className="fab-button"
          onClick={() => {
            setShowModal(true);
          }}
          title="New User" // Ini akan menampilkan teks saat dihover
        >
          +
        </button>
      </div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span
              className="close"
              onClick={() => {
                setShowModal(false);
                setEdit(null);
                setId(0);
                setUser({
                  name: "",
                });
                setEditUser({
                  name: "",
                  photo: "",
                });
              }}
            >
              &times;
            </span>
            <h2>{edit ? "Edit Review" : "New Review"}</h2>
            <div className="my-5">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="floatingNama"
                  placeholder="Masuka Nama Pelanggan"
                  onChange={(e) => {
                    if (edit) {
                      setEditUser({ ...editUser, name: e.target.value });
                    } else {
                      // Jika sedang dalam mode "New", masukkan ke User
                      setUser({ ...User, name: e.target.value });
                    }
                  }}
                  value={edit ? editUser.name : User.name}
                />
                <label for="floatingNama">Nama Pelanggan</label>
              </div>
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="floatingProfesi"
                  placeholder="Masuka Profesi"
                  onChange={(e) => {
                    if (edit) {
                      setEditUser({ ...editUser, profession: e.target.value });
                    } else {
                      // Jika sedang dalam mode "New", masukkan ke User
                      setUser({ ...User, profession: e.target.value });
                    }
                  }}
                  value={edit ? editUser.profession : User.profession}
                />
                <label for="floatingProfesi">Profesi</label>
              </div>
              <div className="mb-3">
                <label htmlFor="select-avatar">Pilih Avatar</label>
                <Select
                  options={options}
                  onChange={handleChange}
                  components={{ Option: CustomOption }}
                  classNamePrefix="select-avatar"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="select-star">Pilih Rating</label>
                <Select
                  options={starOptions}
                  onChange={handleStarChange}
                  components={{ Option: StarOption }}
                  classNamePrefix="select-star"
                />
              </div>

              <div class="mb-3">
                <label for="floatingTextarea3">Testimonial</label>
                <textarea
                  class="form-control"
                  id="floatingTextarea3"
                  placeholder="Masuka Review Dari Pelanggan"
                  onChange={(e) => {
                    if (edit) {
                      setEditUser({
                        ...editUser,
                        testimonial: e.target.value,
                      });
                    } else {
                      // Jika sedang dalam mode "New", masukkan ke User
                      setUser({ ...User, testimonial: e.target.value });
                    }
                  }}
                  value={edit ? editUser.testimonial : User.testimonial}
                  style={{ height: "200px" }}
                ></textarea>
              </div>

              <button
                type="submit"
                onClick={() => {
                  if (edit) {
                    editData();
                  } else {
                    newKarir();
                  }
                }}
                className="btn btn-primary"
              >
                Simpan
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Reviews;
