import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import "./style.css";
import {
  deleteUser,
  editDataUser,
  register,
  userById,
} from "../../fetch/userFetch";
import Swal from "sweetalert2";
import {
  addKarir,
  deleteKarir,
  karirById,
  updateKarir,
} from "../../fetch/karirFetch";

const Karir = () => {
  const [data, setData] = useState([]);
  const [provinsi, setProvinsi] = useState([]);
  const [cities, setCities] = useState([]);
  const [code, setCode] = useState(0);
  const [search, setSearch] = useState("");
  const [msg, setMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [User, setUser] = useState([
    {
      job_title: "",
      job_description: "",
      job_location: "",
      employment_type: "",
      requirements: "",
      category: "",
    },
  ]);
  const [editUser, setEditUser] = useState([
    {
      job_title: "",
      job_description: "",
      job_location: "",
      employment_type: "",
      requirements: "",
      category: "",
    },
  ]);
  const [id, setId] = useState(0);

  const [edit, setEdit] = useState(null);

  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  useEffect(() => {
    fetchData();
    fetchProvinsi();
    fetchKota();
  }, [search, code]);

  const fetchProvinsi = async () => {
    try {
      const apiKey =
        "cab3171a3ed4221835e9a860756533201915f91be1a976ebc862711b8b8e3551";
      const respone = await axios.get(
        `https://api.binderbyte.com/wilayah/provinsi?api_key=${apiKey}`
      );
      setProvinsi(respone.data.value);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchKota = async () => {
    try {
      const apiKey =
        "cab3171a3ed4221835e9a860756533201915f91be1a976ebc862711b8b8e3551";

      const kode = code;
      const result = await axios.get(
        `https://api.binderbyte.com/wilayah/kabupaten?api_key=${apiKey}&id_provinsi=${kode}`
      );
      setCities(result.data.value);
      console.log(result.data.value);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${apiDomain}/api/karirs?search_query=${search}`
      );
      setData(response.data.rows);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setMsg("");
  };

  const newKarir = () => {
    const karirData = {
      job_title: User.job_title,
      job_description: User.job_description,
      job_location: User.job_location,
      employment_type: User.employment_type,
      requirements: User.requirements,
      category: User.category,
    };
    User.job_title === "" ||
    User.job_description === "" ||
    User.job_location === "" ||
    User.employment_type === "" ||
    User.requirements === "" ||
    User.category === ""
      ? Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please Input Data First !!",
        })
      : addKarir(User).then(() => {
          fetchData(); // Memperbarui data
          setShowModal(false); // Menutup modal
          setUser({
            job_title: "",
            job_description: "",
            job_location: "",
            employment_type: "",
            requirements: "",
            category: "",
          });
        });
  };

  const handleDelete = (id) => {
    deleteKarir(id, fetchData);
  };

  const handleEdit = (user) => {
    setEdit(user); // Memasukkan data user ke state
    setShowModal(true); // Menampilkan modal
    setId(user);
    karirById(user, (cb) => {
      setEditUser({
        job_title: cb.job_title,
        job_description: cb.job_description,
        job_location: cb.job_location,
        employment_type: cb.employment_type,
        requirements: cb.requirements,
        category: cb.category,
      });
    });
  };

  const editData = () => {
    if (editUser && id !== 0) {
      const updatedUserData = {
        id: id,
        job_title: editUser.job_title,
        job_description: editUser.job_description,
        job_location: editUser.job_location,
        employment_type: editUser.employment_type,
        requirements: editUser.requirements,
        category: editUser.category,
      };

      console.log(updatedUserData);
      updateKarir(id, updatedUserData)
        .then(() => {
          fetchData();
          setShowModal(false);
          setEdit(null);
          setId(0);
        })
        .catch((error) => {
          console.error("Error updating user:", error);
        });
    }
  };
  return (
    <>
      <div className="container dashboard-content">
        <div className="dashboard-content-container">
          <div className="dashboard-content-header">
            <div className="dashboard-content-tittle">
              <h2>Job List</h2>
            </div>
            <form onSubmit={handleSearch}>
              <div className="dashboard-content-search">
                <input
                  type="text"
                  className="dashboard-content-input"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Find something here..."
                />
                <button type="submit" className="btn btn-info">
                  Search
                </button>
              </div>
            </form>
          </div>

          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <th>No</th>
                <th>Title</th>
                <th>Description</th>
                <th>Type</th>
                <th>Require</th>
                <th>Category</th>
                <th>Action</th>
              </thead>

              {data ? (
                <tbody>
                  {data.map((karir, i) => (
                    <tr key={i}>
                      <td>
                        <span>{i + 1}</span>
                      </td>
                      <td>
                        <span>{karir.job_title}</span>
                      </td>
                      <td>
                        <span>{karir.job_description}</span>
                      </td>
                      <td>
                        <span>{karir.employment_type}</span>
                      </td>
                      <td>
                        <span>{karir.requirements}</span>
                      </td>
                      <td>
                        <span>{karir.category}</span>
                      </td>
                      <td>
                        <div
                          class="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            class="btn btn-secondary"
                            onClick={() => handleEdit(karir.id)}
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            class="btn btn-danger"
                            onClick={() => handleDelete(karir.id)}
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <div className="text-center">Data Tidak Ditemukan</div>
              )}
            </table>
          </div>
        </div>
      </div>
      <div className="fab">
        <button
          type="button"
          className="fab-button"
          onClick={() => {
            setShowModal(true);
          }}
          title="New User" // Ini akan menampilkan teks saat dihover
        >
          +
        </button>
      </div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span
              className="close"
              onClick={() => {
                setShowModal(false);
                setEdit(null);
                setId(0);
                setUser({
                  name: "",
                });
                setEditUser({
                  name: "",
                  image: "",
                });
              }}
            >
              &times;
            </span>
            <h2>{edit ? "Edit Job" : "New Job"}</h2>
            <div className="my-5">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="floatingInput"
                  placeholder="Masuka Title Job"
                  onChange={(e) => {
                    if (edit) {
                      setEditUser({ ...editUser, job_title: e.target.value });
                    } else {
                      // Jika sedang dalam mode "New", masukkan ke User
                      setUser({ ...User, job_title: e.target.value });
                    }
                  }}
                  value={edit ? editUser.job_title : User.job_title}
                />
                <label for="floatingInput">Title</label>
              </div>
              <div class="form-floating mb-3">
                <textarea
                  class="form-control"
                  id="floatingTextarea2"
                  placeholder="Masuka Title Job"
                  onChange={(e) => {
                    if (edit) {
                      setEditUser({
                        ...editUser,
                        job_description: e.target.value,
                      });
                    } else {
                      // Jika sedang dalam mode "New", masukkan ke User
                      setUser({ ...User, job_description: e.target.value });
                    }
                  }}
                  value={edit ? editUser.job_description : User.job_description}
                  style={{ height: "100px" }}
                ></textarea>
                <label for="floatingTextarea2">Description</label>
              </div>
              <div class="row g-2">
                <div class="col-md">
                  <div className="form-floating mb-3">
                    <select
                      className="form-select"
                      id="floatingSelect"
                      onChange={(e) => {
                        setCode(e.target.value);
                      }}
                    >
                      <option value="">Pilih Provinsi</option>
                      {provinsi.map((prov) => (
                        <option key={prov.id} value={prov.id}>
                          {prov.name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="floatingSelect">Provinsi</label>
                  </div>
                </div>
                <div class="col-md">
                  <div className="form-floating mb-3">
                    <select
                      className="form-select"
                      id="floatingSelect"
                      onChange={(e) => {
                        if (edit) {
                          setEditUser({
                            ...editUser,
                            job_location: e.target.value,
                          });
                        } else {
                          // Jika sedang dalam mode "New", masukkan ke User
                          setUser({ ...User, job_location: e.target.value });
                        }
                      }}
                    >
                      {edit ? (
                        <>
                          <option value={editUser.job_location}>
                            {editUser.job_location}
                          </option>
                          {cities.map((city) => (
                            <option key={city.id} value={city.name}>
                              {city.name}
                            </option>
                          ))}
                        </>
                      ) : (
                        <>
                          <option value="">Pilih Kota / Kabupaten</option>
                          {cities.map((city) => (
                            <option key={city.id} value={city.name}>
                              {city.name}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                    <label htmlFor="floatingSelect">Kota/Kabupaten</label>
                  </div>
                </div>
              </div>
              <div className="form-floating mb-3">
                <select
                  className="form-select"
                  id="floatingSelectType"
                  onChange={(e) => {
                    if (edit) {
                      setEditUser({
                        ...editUser,
                        employment_type: e.target.value,
                      });
                    } else {
                      // Jika sedang dalam mode "New", masukkan ke User
                      setUser({ ...User, employment_type: e.target.value });
                    }
                  }}
                >
                  {edit ? (
                    <>
                      <option value={editUser.employment_type}>
                        {editUser.employment_type}
                      </option>
                      <option value="Full Time">Fulltime </option>
                      <option value="Parttime">Parttime </option>
                    </>
                  ) : (
                    <>
                      <option value="">Pilih Tipe Pekerjaan </option>
                      <option value="Full Time">Fulltime </option>
                      <option value="Parttime">Parttime </option>
                    </>
                  )}
                </select>
                <label htmlFor="floatingSelectType">Tipe Pekerjaan</label>
              </div>
              <div className="form-floating mb-3">
                <select
                  className="form-select"
                  id="floatingSelectType"
                  onChange={(e) => {
                    if (edit) {
                      setEditUser({
                        ...editUser,
                        category: e.target.value,
                      });
                    } else {
                      // Jika sedang dalam mode "New", masukkan ke User
                      setUser({ ...User, category: e.target.value });
                    }
                  }}
                >
                  {edit ? (
                    <>
                      <option value={editUser.category}>
                        {editUser.category}
                      </option>
                      <option value="Teknologi Informasi">
                        Teknologi Informasi
                      </option>
                      <option value="Telemarketing">Telemarketing </option>
                    </>
                  ) : (
                    <>
                      <option value="">Pilih Category Pekerjaan </option>
                      <option value="Teknologi Informasi">
                        Teknologi Informasi
                      </option>
                      <option value="Telemarketing">Telemarketing </option>
                      <option value="Marketing">Marketing </option>
                    </>
                  )}
                </select>
                <label htmlFor="floatingSelectType">Category</label>
              </div>
              <div class="form-floating mb-3">
                <textarea
                  class="form-control"
                  id="floatingTextarea3"
                  placeholder="Masuka Requirements Job"
                  onChange={(e) => {
                    if (edit) {
                      setEditUser({
                        ...editUser,
                        requirements: e.target.value,
                      });
                    } else {
                      // Jika sedang dalam mode "New", masukkan ke User
                      setUser({ ...User, requirements: e.target.value });
                    }
                  }}
                  value={edit ? editUser.requirements : User.requirements}
                  style={{ height: "200px" }}
                ></textarea>
                <label for="floatingTextarea3">Requirements</label>
              </div>

              <button
                type="submit"
                onClick={() => {
                  if (edit) {
                    editData();
                  } else {
                    newKarir();
                  }
                }}
                className="btn btn-primary"
              >
                Simpan
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Karir;
