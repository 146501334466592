import React, { useState } from "react";
import { sendMail } from "../../fetch/emailFetch";

const Email = () => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData] = useState({
    fromEmail: null,
    toEmail: null,
    toName: null,
    bankNorek: null,
    name: null,
  });

  const handleSendEmail = async (e) => {
    e.preventDefault();
    console.log(data);
    setLoadingButton(true);
    const success = await sendMail(data);

    if (success) {
      setLoadingButton(false);
    } else {
      // Jika gagal, Anda dapat menambahkan pesan kesalahan atau tindakan lain yang sesuai
      console.error("Gagal menambahkan Email.");
    }
  };
  return (
    <div className="container dashboard-content">
      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <div className="dashboard-content-tittle">
            <h2>Mail Send</h2>
          </div>
        </div>
        <div>
          <form onSubmit={handleSendEmail}>
            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">Pilih Email</label>
              <div className="col-sm-7">
                <select
                  className="form-select"
                  onChange={(e) => {
                    setData({
                      ...data,
                      fromEmail: e.target.value,
                    });
                  }}
                >
                  <option selected defaultValue>
                    Pilih email
                  </option>

                  <option value="contact@ramusen.com">
                    contact@ramusen.com
                  </option>
                  <option value="noreply@ramusen.com">
                    noreply@ramusen.com
                  </option>
                </select>
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">Email Tujuan</label>
              <div className="col-7">
                <input
                  className="form-control"
                  type="email"
                  placeholder="example@email.com"
                  onChange={(e) => {
                    setData({
                      ...data,
                      toEmail: e.target.value,
                    });
                  }}
                  required
                ></input>
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">Nama Tujuan</label>
              <div className="col-7">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Isi Nama Tujuan"
                  onChange={(e) => {
                    setData({
                      ...data,
                      toName: e.target.value,
                    });
                  }}
                  required
                ></input>
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">
                Bank dan No Rekening
              </label>
              <div className="col-7">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Contoh : BCA 1212921"
                  onChange={(e) => {
                    setData({
                      ...data,
                      bankNorek: e.target.value,
                    });
                  }}
                  required
                ></input>
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">Atas Nama</label>
              <div className="col-7">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Atas Nama Bank"
                  onChange={(e) => {
                    setData({
                      ...data,
                      name: e.target.value,
                    });
                  }}
                  required
                ></input>
              </div>
            </div>
            <div className="row mb-3 submit-button">
              <div className="col-sm-10">
                <button
                  className={`btn btn-sm me-3 btn-primary ${
                    loadingButton ? "" : "d-none"
                  }`}
                  type="button"
                  disabled={loadingButton}
                >
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Mengirim Email....
                </button>
                <button
                  type="submit"
                  className={`btn btn-sm btn-primary me-3 ${
                    loadingButton ? "d-none" : ""
                  }`}
                >
                  Kirim
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Email;
