import DashboardIcon from "../assets/icons/dashboard.svg";
import MoneyIcon from "../assets/icons/money.svg";
import CarIcon from "../assets/icons/car.svg";
import UserIcon from "../assets/icons/user.svg";
import HouseIcon from "../assets/icons/house-solid.svg";
import karirIcon from "../assets/icons/karir.svg";
import appIcon from "../assets/icons/app.svg";
import emailIcon from "../assets/icons/email.svg";
import reveiewIcon from '../assets/icons/review.svg'
import lockIcon from '../assets/icons/lock.svg'
const sidebar_menu = [
  {
    id: 1,
    icon: DashboardIcon,
    path: "/",
    title: "Dashboard",
  },
  {
    id: 2,
    icon: HouseIcon,
    path: "/kta",
    title: "KTA",
  },
  {
    id: 3,
    icon: CarIcon,
    path: "/multigunas",
    title: "MULTIGUNA",
  },
  {
    id: 4,
    icon: MoneyIcon,
    path: "/earns",
    title: "EARN",
  },
  {
    id: 5,
    icon: UserIcon,
    path: "/users",
    title: "Users",
  },
  {
    id: 6,
    icon: karirIcon,
    path: "/karirs",
    title: "Job",
  },
  {
    id: 7,
    icon: appIcon,
    path: "/apps",
    title: "Job App",
  },
  {
    id: 8,
    icon: emailIcon,
    path: "/mails",
    title: "Mail",
  },
  {
    id: 9,
    icon: reveiewIcon,
    path: "/testimonials",
    title: "Testimoni",
  },

  {
    id: 10,
    icon: lockIcon,
    path: "/documents",
    title: "Document",
  },
];

export default sidebar_menu;
