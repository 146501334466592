import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { getData } from "../../fetch/dashboardFetch";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [totalUsers, setTotalUsers] = useState(0);
  const [data, setData] = useState(null);
  const chartRef = useRef(null);
  const chartInstance = useRef(null); // Tambahkan ini
  const dateValue = new Date();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = await getData("your_API_URL_here");
        setData(fetchedData);
      } catch (error) {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: error.message,
          showConfirmButton: false,
          timer: 1500,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!loading && data && chartRef.current) {
      renderChart(data);
    }
  }, [loading, data]);

  const renderChart = (chartData) => {
    const dates = chartData.map((item) => item.date);
    const uvValues = chartData.map((item) => item.uv);
    const totalUsers = uvValues.reduce((a, b) => a + b, 0);
    setTotalUsers(totalUsers);
    const today = new Date();
    const formattedToday = formatReadableDate(today);
    console.log(formattedToday);

    if (chartInstance.current) {
      chartInstance.current.destroy(); // Hancurkan instance chart lama
    }

    chartInstance.current = new Chart(chartRef.current, {
      type: "line",
      data: {
        labels: dates,
        datasets: [
          {
            label: "Users",
            data: uvValues,
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          title: {
            display: true,
            text: "Report Active Users", // Ganti dengan judul yang Anda inginkan
            fontSize: 24,
          },
          subtitle: {
            display: true,
            text: `${formattedToday}`,
          },
        },
      },
    });
  };
  const formatReadableDate = (date) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const year = date.getFullYear();

    const month = months[date.getMonth()];

    return ` ${year}`;
  };

  return (
    <div className="container dashboard-content">
      {loading ? (
        <div className="text-center my-5">
          <FontAwesomeIcon icon={faSpinner} spin className="loading-spinner" />
        </div>
      ) : (
        <div className="dashboard-content-container">
          <div className="dashboard-content-header">
            <h2>Dashboard</h2>
          </div>
          <div className="container">
            <div className="row mb-5">
              <div className="col-lg-8">
                <div className="card p-3">
                  <div className="card-title fw-bold">Report Active Users</div>

                  <canvas ref={chartRef} width="400" height="200"></canvas>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card p-3 h-100">
                  <div className="card-title mb-5 fw-bold">
                    Total Active Users
                  </div>
                  <div className="card-body text-center">
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ color: "#346bcb", fontSize: "124px" }}
                    />
                    <div className="total-users mt-5">{totalUsers}</div>
                    <h6>Active Users</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-lg-8">
                <div className="card p-3 ">
                  <div className="card-title fw-bold">Maps</div>
                  <div className="card-body"></div>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.0110714604607!2d106.8206831746205!3d-6.392572362524141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ebe367666695%3A0x64cf661c965e5f0e!2sRamusen!5e0!3m2!1sen!2sid!4v1696834275150!5m2!1sen!2sid"
                    width="725"
                    height="400"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card p-3   mb-5 h-100">
                  <div class="card-title fw-bold">Calendar</div>
                  <div className="card-body">
                    <Calendar value={dateValue} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
