import axios from "axios";
import Swal from "sweetalert2";

const apiDomain = process.env.REACT_APP_API_DOMAIN;
const URL = apiDomain + "/api/testimonials";

const getAllReview = async (cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: `${URL}`,
    });
    cb(result.data);
  } catch (err) {
    console.log(err);
  }
};

const addReviews = async (obj) => {
  try {
    let result = await axios({
      method: "POST",
      url: `${URL}/create`,
      data: obj,
    });
    Swal.fire({
      icon: "success",
      title: "New User Successfully Added",
      text: `Success!`,
    });
    obj(result.data);
  } catch (err) {
    console.log(err);
  }
};

const reviewById = async (id, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + `/${id}`,
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
  }
};
const updateReview = async (id, data) => {
  try {
    let result = await axios({
      method: "PUT",
      url: URL + `/update/${id}`,

      data: data,
    });
    console.log(result.data);
    Swal.fire("Edit Item", "Your Data has been Updated!", "success");
  } catch (err) {
    console.log(err);
  }
};
const deleteReview = async (id, cb) => {
  try {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let result = await axios({
          method: "DELETE",
          url: URL + `/delete/${id}`,
        });
        Swal.fire(
          "Deleted!",
          `Your result with id ${id} has been deleted.`,
          "success"
        );
        if (cb) {
          cb();
        }
      }
    });
  } catch (err) {
    console.log(err);
  }
};

export { getAllReview, addReviews, reviewById, deleteReview, updateReview };
