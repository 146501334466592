import React, { useRef, useState, useEffect } from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { userLogin, checkAdmin } from "../../fetch/userFetch";
import logo from "../../assets/image/logo.png";

const Login = (props) => {
  const { loginStatus, loginHandler } = props;
  const [form, setForm] = useState({
    name: "",
    password: "",
  });

  const submitHandler = (e) => {
    e.preventDefault();
    userLogin(form, (result) => {
      localStorage.setItem("usertoken", result.user_token);
      loginHandler(true);
    });
  };

  const navigation = useNavigate();
  useEffect(() => {
    if (loginStatus) {
      navigation("/");
    }
  }, [loginStatus, navigation]);

  return (
    <>
      <div className="animated bounceInDown">
        <div className="container">
          <span className="error animated tada" id="msg"></span>

          <form name="form1" className="box" onSubmit={submitHandler}>
            <img src={logo} className="img-fluid w-50" />
            <h5>Sign in to your account.</h5>

            <input
              type="text"
              name="email"
              placeholder="Email"
              autoComplete="off"
              onChange={(e) => setForm({ ...form, name: e.target.value })}
            />
            <i className="typcn typcn-eye" id="eye"></i>
            <input
              type="password"
              name="password"
              placeholder="Password"
              id="pwd"
              autoComplete="off"
              onChange={(e) => setForm({ ...form, password: e.target.value })}
            />

            <input type="submit" value="Sign in" className="btn1" />
          </form>

          <a href="#" className="dnthave">
            Don’t have an account? Sign up
          </a>
        </div>

        <div className="footer">
          <span>
            Made with <i className="fa-link fa-heart pulse"></i>
            <a href="https://www.google.de/maps/place/Augsburger+Puppenkiste/@48.360357,10.903245,17z/data=!3m1!4b1!4m2!3m1!1s0x479e98006610a511:0x73ac6b9f80c4048f">
              <a href="https://ramusen.com">PT. Ranajaya Mulia Sentosa</a>
            </a>
          </span>
        </div>
      </div>
    </>
  );
};

export default Login;
