import React, { useState } from "react";
import Modal from "react-modal";
import { useNavigate } from 'react-router-dom';
import "./style.css";

Modal.setAppElement("#root");

const Gatekeeper = ({ children }) => {
  const [password, setPassword] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [error, setError] = useState("");
  const correctPassword = "rahasia123";
  const navigate = useNavigate(); // Inisialisasi useNavigate

  const checkPassword = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setModalIsOpen(false);
      setError("");
    } else {
      setError("Password salah!");
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setError("");
    navigate('/'); // Arahkan pengguna ke halaman home
  };

  if (!modalIsOpen) {
    return <>{children}</>;
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Password Required"
      className="Modal"
      overlayClassName="Overlay"
    >
      <h1>Masukkan Password untuk Mengakses Dokumen</h1>
      <form onSubmit={checkPassword}>
        <div className="mb-3">
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Masukkan password"
            autoFocus
          />
        </div>
        {error && <p className="text-danger">{error}</p>}
        <div className="modal-footer">
          <button className="btn btn-sm btn-info mx-2" type="submit">
            Submit
          </button>
          <button className="btn btn-sm btn-danger" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default Gatekeeper;
