import axios from "axios";
import Swal from "sweetalert2";

const apiDomain = `https://api.ramusen.io`;
const URL = apiDomain + "/api/analytics";

const getData = async () => {
  try {
    let result = await axios({
      method: "GET",
      url: URL,
    });
    return result.data;
  } catch (error) {
    console.log(error);
    throw new Error(error.response.data.message);
  }
};

export { getData };
