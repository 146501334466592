import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";

const Document = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [msg, setMsg] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const [showModal, setShowModal] = useState(false);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, search, perPage]);
  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!file) {
      alert("Please select a file.");
      return;
    }
    const formData = new FormData();
    formData.append("document", file);
    formData.append("fileName", fileName);

    // Implement your logic to send formData to the server here
    console.log("File to upload:", file.name);
    setShowModal(false); // Close the modal after form submission

    axios
      .post(`${apiDomain}/api/docs/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          usertoken: localStorage.getItem("usertoken"),
        },
      })
      .then((response) => {
        console.log("File uploaded successfully:", response.data);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Document uploaded successfully",
        });
        fetchData();
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to upload document",
        });
      });
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${apiDomain}/api/docs?search_query=${search}&page=${currentPage}&perPage=${perPage}&search=${search}`,
        {
          headers: {
            "Content-Type": "application/json",
            usertoken: localStorage.getItem("usertoken"),
          },
        }
      );
      const responseData = response.data;
      setData(responseData.data);
      setTotalPages(Math.ceil(responseData.totalItems / perPage));
    } catch (err) {
      console.log(err);
    }
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  }; // Function untuk menangani perubahan input nama file
  const handleFileNameChange = (event) => {
    setFileName(event.target.value);
  };

  // Function untuk menangani perubahan input konten file
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setMsg("");
    setCurrentPage(0);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${apiDomain}/api/docs/delete/${id}`, {
            headers: {
              "Content-Type": "application/json",
              usertoken: localStorage.getItem("usertoken"),
            },
          })
          .then((response) => {
            console.log(response.data);
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            fetchData();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  };
  return (
    <div className="container dashboard-content">
      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <div className="dashboard-content-tittle">
            <h2>Dokumen Pribadi</h2>
          </div>
          <form onSubmit={handleSearch}>
            <div className="dashboard-content-search">
              <input
                type="text"
                className="dashboard-content-input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Find something here..."
              />
              <button type="submit" className="btn btn-info">
                Search
              </button>
            </div>
          </form>
        </div>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <th>No</th>
              <th>File</th>
              <th>Action</th>
            </thead>
            <tbody>
              {data.map((d, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{d.filename}</td>
                  <td>
                    <a
                      href={`${apiDomain}/api/docs/download/doc/${d.id}`}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-info btn-sm mx-2"
                    >
                      Download
                    </a>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => {
                        handleDelete(d.id);
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <p>
          Total Rows: {data.length} Page: {currentPage} of {totalPages}
        </p>
        <p className="has-text-centered has-text-danger">{msg}</p>
        <ReactPaginate
          previousLabel={"< Prev"}
          nextLabel={"Next >"}
          breakLabel={"..."}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          forcePage={currentPage - 1}
          onPageChange={handlePageChange}
          containerClassName={"pagination-list"}
          pageLinkClassName={"pagination-link"}
          previousLinkClassName={"pagination-link"}
          nextLinkClassName={"pagination-link"}
          activeClassName={"pagination-link is-current"}
          disabledClassName={"pagination-link is-disabled"}
        />
      </div>
      <div className="fab">
        <button
          type="button"
          className="fab-button"
          onClick={() => {
            setShowModal(true);
          }}
          title="Upload Documents" // Ini akan menampilkan teks saat dihover
        >
          +
        </button>
      </div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowModal(false)}>
              &times;
            </span>
            <h2>Upload Document</h2>
            <form onSubmit={handleFormSubmit} className="my-5">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Nama File"
                  value={fileName}
                  onChange={handleFileNameChange}
                />
                <label htmlFor="floatingInput">Nama File</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="file"
                  className="form-control"
                  id="floatingFile"
                  onChange={handleFileChange}
                />
                <label htmlFor="floatingFile">File Content</label>
              </div>
              <button type="submit" className="btn btn-primary">
                Simpan
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Document;
