import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import "./style.css";
import {
  deleteUser,
  editDataUser,
  register,
  userById,
} from "../../fetch/userFetch";
import Swal from "sweetalert2";

const User = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [msg, setMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [User, setUser] = useState([
    {
      name: "",
      password: "",
    },
  ]);
  const [editUser, setEditUser] = useState([
    {
      name: "",
      password: "",
      image: "",
    },
  ]);
  const [id, setId] = useState(0);
  const [userImage, setUserImage] = useState(null);

  const [edit, setEdit] = useState(null);

  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  useEffect(() => {
    fetchData();
  }, [search]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${apiDomain}/api/users?search_query=${search}`
      );
      console.log(response.data.rows);
      setData(response.data.rows);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setMsg("");
  };

  const addUser = () => {
    User.name === "" || User.password === ""
      ? Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please Input Data First !!",
        })
      : register(User).then(() => {
          fetchData(); // Memperbarui data
          setShowModal(false); // Menutup modal
          setUser({
            name: "",
            password: "",
          });
        });
  };

  const handleDelete = (id) => {
    deleteUser(id, fetchData);
  };

  const handleEdit = (user) => {
    setEdit(user); // Memasukkan data user ke state
    setShowModal(true); // Menampilkan modal
    setId(user);
    userById(user, (cb) => {
      setEditUser({
        name: cb.name,
        image: cb.image,
      });
    });
  };

  const editData = () => {
    if (editUser && id !== 0) {
      const updatedUserData = {
        id: id,
        name: editUser.name,
        password: editUser.password,
      };

      if (userImage) {
        updatedUserData.image = userImage;
      } else {
        updatedUserData.image = editUser.image;
      }
      console.log(updatedUserData);
      editDataUser(id, updatedUserData)
        .then(() => {
          fetchData();
          setShowModal(false);
          setEdit(null);
          setId(0);
          setEditUser({
            name: "",
            image: "",
          });
          setUserImage(null);
        })
        .catch((error) => {
          console.error("Error updating user:", error);
        });
    }
  };
  return (
    <>
      <div className="container dashboard-content">
        <div className="dashboard-content-container">
          <div className="dashboard-content-header">
            <div className="dashboard-content-tittle">
              <h2>User List</h2>
            </div>
            <form onSubmit={handleSearch}>
              <div className="dashboard-content-search">
                <input
                  type="text"
                  className="dashboard-content-input"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Find something here..."
                />
                <button type="submit" className="btn btn-info">
                  Search
                </button>
              </div>
            </form>
          </div>

          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <th>ID</th>
                <th>NAME</th>
                <th>FOTO</th>
                <th>Action</th>
              </thead>

              {data ? (
                <tbody>
                  {data.map((user, i) => (
                    <tr key={i}>
                      <td>
                        <span>{user.id}</span>
                      </td>
                      <td>
                        <span>{user.name}</span>
                      </td>
                      <td>
                        <Link to={apiDomain + "/" + user.image}>
                          <img
                            src={apiDomain + "/" + user.image}
                            className="dashboard-content-avatar"
                          />
                        </Link>
                      </td>
                      <td>
                        <div
                          class="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            class="btn btn-secondary"
                            onClick={() => handleEdit(user.id)}
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            class="btn btn-danger"
                            onClick={() => handleDelete(user.id)}
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <div className="text-center">Data Tidak Ditemukan</div>
              )}
            </table>
          </div>
        </div>
      </div>
      <div className="fab">
        <button
          type="button"
          className="fab-button"
          onClick={() => {
            setShowModal(true);
          }}
          title="New User" // Ini akan menampilkan teks saat dihover
        >
          +
        </button>
      </div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span
              className="close"
              onClick={() => {
                setShowModal(false);
                setEdit(null);
                setId(0);
                setUser({
                  name: "",
                });
                setEditUser({
                  name: "",
                  image: "",
                });
              }}
            >
              &times;
            </span>
            <h2>{edit ? "Edit User" : "New User"}</h2>
            <div className="my-5">
              {edit && (
                <div className="text-center mb-3">
                  <div>
                    <img
                      src={apiDomain + "/" + editUser.image}
                      className="dashboard-content-avatar-edit"
                      alt={"Profile Picture"}
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      }
                    />
                    <input
                      type="file"
                      id="fileInput"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={(e) => setUserImage(e.target.files[0])}
                    />
                  </div>
                  <label>Change Image</label>
                </div>
              )}
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="floatingInput"
                  placeholder="Masukan Username"
                  onChange={(e) => {
                    if (edit) {
                      // Jika sedang dalam mode "Edit", masukkan ke editUser
                      setEditUser({ ...editUser, name: e.target.value });
                    } else {
                      // Jika sedang dalam mode "New", masukkan ke User
                      setUser({ ...User, name: e.target.value });
                    }
                  }}
                  value={edit ? editUser.name : User.name}
                />
                <label for="floatingInput">Username</label>
              </div>
              <div class="form-floating mb-3">
                <input
                  type="password"
                  class="form-control"
                  id="floatingPassword"
                  onChange={(e) => {
                    if (edit) {
                      // Jika sedang dalam mode "Edit", masukkan ke editUser
                      setEditUser({ ...editUser, password: e.target.value });
                    } else {
                      // Jika sedang dalam mode "New", masukkan ke User
                      setUser({ ...User, password: e.target.value });
                    }
                  }}
                  placeholder="Password"
                />
                <label for="floatingPassword">Password</label>
              </div>
              <button
                type="submit"
                onClick={() => {
                  if (edit) {
                    editData();
                  } else {
                    addUser();
                  }
                }}
                className="btn btn-primary"
              >
                Simpan
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default User;
