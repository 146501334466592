import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./Styles/App.css";
import "bootstrap/dist/css/bootstrap.css";

import SideBar from "./components/Sidebar";
import sidebar_menu from "./constants/sidebar-menu";

import Login from "./pages/Login";
import Kta from "./components/Kta";
import Multiguna from "./components/Multiguna";
import Earn from "./components/Earn";
import User from "./components/User";
import Dashboard from "./components/Dashboard";
import Karir from "./components/Karir";
import Application from "./components/Application";
import Email from "./components/Email";
import Reviews from "./components/Reviews";
import Documents from "./components/Documents";
import Gatekeeper from "./components/Gatekeeper";
function App() {
  const [loginStatus, setLoginStatus] = useState(false);
  const [loading, setLoading] = useState(true);

  const loginHandler = (result) => {
    setLoginStatus(result);
  };

  useEffect(() => {
    if (localStorage.getItem("usertoken")) {
      setLoginStatus(true);
    } else {
      setLoginStatus(false);
    }
    setLoading(false);
  }, [loginStatus]);
  return (
    <Router>
      {loading ? (
        <div></div>
      ) : loginStatus ? (
        <div className="dashboard-container">
          <SideBar
            menu={sidebar_menu}
            loginStatus={loginStatus}
            loginHandler={loginHandler}
          />
          <div className="dashboard-body">
            <Routes>
              <Route path="*" element={<div></div>} />
              <Route exact path="/" element={<Dashboard />} />
              <Route exact path="/kta" element={<Kta />} />
              <Route exact path="/multigunas" element={<Multiguna />} />
              <Route exact path="/earns" element={<Earn />} />
              <Route exact path="/users" element={<User />} />
              <Route exact path="/karirs" element={<Karir />} />
              <Route exact path="/apps" element={<Application />} />
              <Route exact path="/mails" element={<Email />} />
              <Route exact path="/testimonials" element={<Reviews />} />
              <Route
                path="/documents"
                element={
                  <Gatekeeper>
                    <Documents />
                  </Gatekeeper>
                }
              />
            </Routes>
          </div>
        </div>
      ) : (
        <Routes>
          <Route
            path="*"
            element={
              <Login loginStatus={loginStatus} loginHandler={loginHandler} />
            }
          />
        </Routes>
      )}
    </Router>
  );
}

export default App;
