import React, { useState, useEffect } from "react";
import axios from "axios";

import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { format } from "date-fns";

import "./style.css";

const Earn = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [msg, setMsg] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  useEffect(() => {
    fetchData();
  }, [currentPage, search, perPage]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${apiDomain}/api/earns?search_query=${search}&page=${currentPage}&perPage=${perPage}&search=${search}`
      );

      const responseData = response.data;
      setData(responseData.data);
      setTotalPages(Math.ceil(responseData.totalItems / perPage));
    } catch (err) {
      console.log(err);
    }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setMsg("");
    setCurrentPage(0);
  };

  const handleToggleRow = (rowId) => {
    // Fungsi untuk mengganti status pemilihan baris
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
      handleSendToWhatsApp();
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  const handleSendToWhatsApp = async () => {
    // Mengumpulkan data dari baris yang dipilih
    const selectedData = data.filter((item, i) => selectedRows.includes(i));
    const selectedNumbers = selectedRows
      .map((i) => {
        const phoneNumber = data[i].mobile_number;
        // Menghapus nol di depan nomor telepon jika ada
        const formattedNumber = phoneNumber.startsWith("0")
          ? phoneNumber.slice(1)
          : phoneNumber;
        return "+62" + formattedNumber;
      })
      .join(",");
    // Menyiapkan data untuk dikirim ke WhatsApp
    const formattedData = selectedData
      .map((item, i) => {
        return `
            ${i + 1}. 
            Name: ${item.applicant_name}
            Mobile Number: ${item.mobile_number}
            Email: ${item.email}
            Company Name: ${item.company_name}
            Job: ${item.job}
            Created Date: ${item.created_dttm}
          `;
      })
      .join("\n\n"); // Menggabungkan data dengan double line break

    const whatsappLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      formattedData
    )}&phone=${selectedNumbers}`;

    // Membuka tautan WhatsApp di jendela baru
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="container dashboard-content">
      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <div className="dashboard-content-tittle">
            <h2>Earn Extra Money</h2>
          </div>
          <form onSubmit={handleSearch}>
            <div className="dashboard-content-search">
              <input
                type="text"
                className="dashboard-content-input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Find something here..."
              />
              <button type="submit" className="btn btn-info">
                Search
              </button>
            </div>
          </form>
        </div>

        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <th scope="col">No.</th>
              <th scope="col">Name</th>
              <th scope="col">Mobile Number</th>
              <th scope="col">Email</th>
              <th scope="col">Company Name</th>
              <th scope="col">Job</th>
              <th scope="col">Created Date</th>
              <th scope="col">Action</th>
            </thead>

            <tbody>
              {data.map((item, i) => (
                <tr key={item.id || i}>
                  <td>{i + 1}</td>
                  <td>{item.applicant_name}</td>
                  <td>{item.mobile_number}</td>
                  <td>{item.email}</td>
                  <td>{item.company_name}</td>
                  <td>{item.job}</td>
                  <td>
                    {format(new Date(item.created_dttm), "dd-MM-yyyy HH:mm:ss")}
                  </td>
                  <td>
                    <div>
                      <a
                        className="btn btn-success"
                        onClick={() => handleToggleRow(i)}
                        disabled={selectedRows.length === 0}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faWhatsapp} /> Start Chat
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <p>
          Total Rows: {data.length} Page: {currentPage} of {totalPages}
        </p>
        <p className="has-text-centered has-text-danger">{msg}</p>
        <ReactPaginate
          previousLabel={"< Prev"}
          nextLabel={"Next >"}
          breakLabel={"..."}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          forcePage={currentPage - 1}
          onPageChange={handlePageChange}
          containerClassName={"pagination-list"}
          pageLinkClassName={"pagination-link"}
          previousLinkClassName={"pagination-link"}
          nextLinkClassName={"pagination-link"}
          activeClassName={"pagination-link is-current"}
          disabledClassName={"pagination-link is-disabled"}
        />
      </div>
    </div>
  );
};

export default Earn;
