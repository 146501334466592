import axios from "axios";
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import "./style.css";
const Application = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [msg, setMsg] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  useEffect(() => {
    fetchData();
  }, [currentPage, search, perPage]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${apiDomain}/api/app?search_query=${search}&page=${currentPage}&perPage=${perPage}&search=${search}`
      );
      console.log("Respone Data : ", response.data);
      const responseData = response.data;
      setData(responseData.data);
      setTotalPages(Math.ceil(responseData.totalItems / perPage));
    } catch (err) {
      console.log(err);
    }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setMsg("");
    setCurrentPage(0);
  };

  const handleToggleRow = (file) => {
    window.open(apiDomain + "/" + file, "_blank");
  };

  return (
    <div className="container dashboard-content">
      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <div className="dashboard-content-tittle">
            <h2>Dokumen Pribadi</h2>
          </div>
          <form onSubmit={handleSearch}>
            <div className="dashboard-content-search">
              <input
                type="text"
                className="dashboard-content-input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Find something here..."
              />
              <button type="submit" className="btn btn-info">
                Search
              </button>
            </div>
          </form>
        </div>

        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <th scope="col">No.</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Address</th>
              <th scope="col">Job Submit</th>
              <th scope="col">Date Applied</th>
              <th scope="col">CV</th>
            </thead>

            <tbody>
              {data.map((app, i) => (
                <tr key={app.id || i}>
                  <td>{i + 1}</td>
                  <td>{`${app.first_name} ${app.last_name}`}</td>
                  <td>{app.email}</td>
                  <td>{app.phone_number}</td>
                  <td>{app.address}</td>
                  <td>{app.rf_karir.job_title}</td>
                  <td>
                    {format(new Date(app.date_applied), "dd-MM-yyyy HH:mm:ss")}
                  </td>
                  <td>
                    <div>
                      <a
                        className="btn btn-primary"
                        href={`${apiDomain}/api/app/downloads/${app.cv_file}`}
                        onClick={() => handleToggleRow(app.cv_file)}
                        disabled={selectedRows.length === 0}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faDownload} />
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <p>
          Total Rows: {data.length} Page: {currentPage} of {totalPages}
        </p>
        <p className="has-text-centered has-text-danger">{msg}</p>
        <ReactPaginate
          previousLabel={"< Prev"}
          nextLabel={"Next >"}
          breakLabel={"..."}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          forcePage={currentPage - 1}
          onPageChange={handlePageChange}
          containerClassName={"pagination-list"}
          pageLinkClassName={"pagination-link"}
          previousLinkClassName={"pagination-link"}
          nextLinkClassName={"pagination-link"}
          activeClassName={"pagination-link is-current"}
          disabledClassName={"pagination-link is-disabled"}
        />
      </div>
    </div>
  );
};

export default Application;
