import axios from "axios";
import Swal from "sweetalert2";

const apiDomain = process.env.REACT_APP_API_DOMAIN;
const URL = apiDomain + "/api/emails";

const sendMail = async (obj) => {
  try {
    let result = await axios({
      method: "POST",
      url: `${URL}/send`,
      data: obj,
    });
    Swal.fire({
      icon: "success",
      title: "Berhasil mengirim pesan",
      text: `Success!`,
    });
    return true;
  } catch (error) {
    console.log(error);
    let message = "Terjadi kesalahan, silakan coba lagi.";
    // Cek jika error.response dan error.response.data ada
    if (error.response && error.response.data) {
      message = error.response.data.message || message;
    } else if (error.request) {
      // Error terkait dengan request yang dibuat tapi tidak mendapatkan response
      message = "Tidak ada response dari server.";
    } else {
      // Error yang terjadi sebelum melakukan request
      message = error.message || message;
    }

    Swal.fire({
      position: "center",
      icon: "error",
      title: message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

export { sendMail };
